import { NgModule } from '@angular/core';
import { DialogComponent } from './dialog/dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [ DialogComponent ],
imports: [CommonModule, FormsModule]
  , exports: [DialogComponent]
})
export class SharedModule {}
